import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PATHS } from '../app.constants';
import { UserHandlerComponent, UserEventsHandlerComponent } from './handlers/user-handler/user-handler.component';
import { loggedGuard } from '../shared/guards/logged.guard';
import { friendGuard } from './guards/friend.guard';
import { UserCountryHandlerComponent } from './handlers/user-country-handler/user-country-handler.component';
import { userResolver } from './resolvers/user-resolver.service';
import { ProfileEditHandlerComponent } from './handlers/profile-edit-handler/profile-edit-handler.component';
import { selfGuard } from './guards/self.guard';
import { FlagsHandlerComponent } from '../shared/handlers/flags-handler/flags-handler.component';

const routes: Routes = [
	{
		path: `${PATHS.user}/:user`,
		component: UserHandlerComponent,
		resolve: { user: userResolver },
		canActivate: [loggedGuard, friendGuard],
		children: [
			{
				path: '',
				component: UserEventsHandlerComponent
			},
			{
				path: 'edit',
				component: ProfileEditHandlerComponent,
				canActivate: [selfGuard]
			},
			{
				path: PATHS.flags,
				component: FlagsHandlerComponent
			},
			{
				path: `${PATHS.flags}/:countryId`,
				component: UserCountryHandlerComponent
			}
		]
	}
];


@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class UsersRoutingModule { }
