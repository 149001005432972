import type { Routes } from '@angular/router';
import { PATHS, STATIC_SECTION } from './app.constants';
import { loggedGuard } from './shared/guards/logged.guard';
import { WallHandlerComponent } from './shared/handlers/wall-handler/wall-handler.component';
import { FrontPageComponent } from './shared/handlers/front-page/front-page.component';

export const mainRoutes: Routes = [
	{
		path: 'login',
		component: FrontPageComponent,
		data: { onlyPublic: true },
		canActivate: [loggedGuard]
	},
	{
		path: PATHS.recover,
		loadChildren: () => import('./recover/recover.module').then(m => m.RecoverModule),
		data: { onlyPublic: true },
		canActivate: [loggedGuard]
	},
	{
		path: PATHS.verify,
		loadChildren: () => import('./verify/verify.module').then(m => m.VerifyModule)
	},
	{
		path: PATHS.register,
		loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
		data: { onlyPublic: true },
		canActivate: [loggedGuard]
	},
	{
		path: PATHS.wall,
		component: WallHandlerComponent,
		canActivate: [loggedGuard]
	},
	{
		path: PATHS.friends,
		loadChildren: () => import('./friends/friends.module').then(m => m.FriendsModule),
		canActivate: [loggedGuard]
	},
	{
		path: PATHS.settings,
		loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
		canActivate: [loggedGuard]
	},
	{
		path: PATHS.groups,
		loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule),
		canActivate: [loggedGuard]
	},

	//#region statics
	{
		path: STATIC_SECTION.about,
		loadChildren: () => import('./statics/statics.module').then(m => m.StaticsModule)
	},
	{
		path: STATIC_SECTION.terms,
		loadChildren: () => import('./statics/statics.module').then(m => m.StaticsModule)
	},
	{
		path: STATIC_SECTION.privacy,
		loadChildren: () => import('./statics/statics.module').then(m => m.StaticsModule)
	},
	{
		path: STATIC_SECTION.help,
		loadChildren: () => import('./statics/statics.module').then(m => m.StaticsModule)
	},
	{
		path: STATIC_SECTION.content,
		loadChildren: () => import('./statics/statics.module').then(m => m.StaticsModule)
	},
	//#endregion statics

	{
		path: PATHS.store,
		loadChildren: () => import('./store/store.module').then(m => m.StoreModule),
	},
	{
		path: '**',
		component: FrontPageComponent
	},
];
