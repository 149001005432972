<dflgr-header-handler [title]="handlerTitle" [backButton]="true"/>
@if (countryList$ | async; as countryList) {
	<div class="list handler-content">
		@for (country of countryList; track trackByCountryName) {
			<p class="letter-separator" *ngIf="isNewLetter(country.name)"><label>{{currentLetter}}</label></p>
			<mat-card class="country-card">
				<mat-card-content>
					<dflgr-country-flag [country]="country"/>
					<div *ngIf="country.city">{{country.city}}</div>
				</mat-card-content>
			</mat-card>
		}
	</div>
} @else {
	<div class="spinner-container"><mat-spinner/></div>
}
