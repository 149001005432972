import { importProvidersFrom, type ApplicationConfig, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { provideRouter, withComponentInputBinding, withRouterConfig } from "@angular/router";
import { UsersModule } from "./users/users.module";
import { FlagsModule } from "./flags/flags.module";
import { SharedModule } from "./shared/shared.module";
import { provideServiceWorker } from '@angular/service-worker';
import { mainRoutes } from "./app-routing.module";
import { StorageService } from "./shared/services/storage.service";
import { FlagsService } from "./shared/services/flags.service";
import { UsersService } from "./shared/services/users.service";
import { AlertService } from "./shared/services/alert.service";
import { AuthService } from "./shared/services/auth.service";
import { provideAnimations } from "@angular/platform-browser/animations";

export const appConfig: ApplicationConfig = {
	providers: [
		importProvidersFrom(
			BrowserModule,
			UsersModule,
			FlagsModule,
			SharedModule,
		),
		provideServiceWorker('ngsw-worker.js', {
			enabled: !isDevMode(),
			registrationStrategy: 'registerWhenStable:30000'
		}),
		provideRouter(mainRoutes, withComponentInputBinding(), withRouterConfig({paramsInheritanceStrategy: 'always'})),
		StorageService,
		FlagsService,
		UsersService,
		AlertService,
		AuthService,
		provideAnimations()
	]
}
