import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GENERAL } from '../app.constants';
import { loggedGuard } from '../shared/guards/logged.guard';
import { EventHandlerComponent } from './handlers/event-handler/event-handler.component';
import { eventResolver } from './resolvers/event-resolver.service';
import { CountryHandlerComponent, CountryEventsHandlerComponent, CountryUsersHandlerComponent } from './handlers/country-handler/country-handler.component';
import { CountriesListHandlerComponent } from './handlers/countries-list-handler/countries-list-handler.component';

const routes: Routes = [
	{
		path: `${GENERAL.handlers.event.path}/:eventid`,
		component: EventHandlerComponent,
		resolve: { flagEvent: eventResolver },
		canActivate: [loggedGuard]
	},
	{
		path: `${GENERAL.handlers.countries.path}/:countryid`,
		component: CountryHandlerComponent,
		canActivate: [loggedGuard],
		children: [
			{
				path: '',
				component: CountryEventsHandlerComponent
			},
			{
				path: 'users',
				component: CountryUsersHandlerComponent
			},
		]
	},
	{
		path: GENERAL.handlers.countries.path,
		component: CountriesListHandlerComponent,
	}
];


@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class FlagsRoutingModule { }
