import { inject } from '@angular/core';
import { Router, type CanActivateFn, type ActivatedRouteSnapshot, type RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../../shared/services/storage.service';
import { PATHS } from '../../app.constants';

export const selfGuard: CanActivateFn = (
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const router = inject(Router);
	const storageService = inject(StorageService);

	const selectedUser = next.parent.params.user;
	const canEdit = storageService.localUser.username === selectedUser;

	if (canEdit) {
		return true;
	}

	router.navigate([PATHS.user, selectedUser]);
	return false;
};
