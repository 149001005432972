import { NgModule } from '@angular/core';
import { FlagsRoutingModule } from './flags-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AddFlagModalComponent } from './components/add-flag-modal/add-flag-modal.component';
import { CountryHandlerComponent, CountryEventsHandlerComponent, CountryUsersHandlerComponent } from './handlers/country-handler/country-handler.component';
import { EventHandlerComponent } from './handlers/event-handler/event-handler.component';
import { CountriesListHandlerComponent } from './handlers/countries-list-handler/countries-list-handler.component';
import { SafeImagePipe } from './pipes/safe-image.pipe';

@NgModule({
	imports: [
		SharedModule,
		FlagsRoutingModule,
		AddFlagModalComponent,
		CountryHandlerComponent,
		CountryEventsHandlerComponent,
		CountryUsersHandlerComponent,
		CountriesListHandlerComponent,
		SafeImagePipe,
		EventHandlerComponent
	],
})
export class FlagsModule {}
