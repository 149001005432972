import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
// import * as COUNTRIESLIST from '@jagomf/countrieslist';
import { en as countriesEN } from '@jagomf/countrieslist';
import { GENERAL } from '../../../app.constants';
import Country from '../../../shared/models/country.model';
import User from '../../../shared/models/user.model';
import { FlagsService } from '../../../shared/services/flags.service';
import { HandlerActionButtonComponent } from '../../../shared/components/handler-action-button/handler-action-button.component';
import { FlagEventListFilteredComponent } from '../../../shared/components/flag-event-list-filtered/flag-event-list-filtered.component';
import { NgIf } from '@angular/common';
import { HeaderHandlerComponent } from '../../../shared/components/header-handler/header-handler.component';

@Component({
	selector: 'dflgr-user-country-handler',
	standalone: true,
	imports: [HeaderHandlerComponent, NgIf, FlagEventListFilteredComponent, HandlerActionButtonComponent],
	templateUrl: './user-country-handler.component.html',
	styleUrls: ['./user-country-handler.component.scss']
})
export class UserCountryHandlerComponent implements OnInit {
	@HostBinding('class.handler') readonly handlerClass = true;
	handlerTitle: string;
	country: Country;
	@Input({ required: true }) user: User; // from route resolver
	@Input({ required: true }) set countryId(countryId: string) { // from route path
		this.country = new Country(countryId);
	}

	constructor(
		private readonly titleHolder: Title,
		private readonly flagsProvider: FlagsService,
	) { }

	get actionButtonAction() {
		return this.requestAddFlag.bind(this);
	}

	private requestAddFlag() {
		this.flagsProvider.requestAddFlag(this.country);
	}

	ngOnInit() {
		// TODO MultiLang: const countryName = COUNTRIESLIST[this.authProvider.localLanguage][this.country.id.toUpperCase()];
		const countryName = countriesEN[this.country.id.toUpperCase()];
		this.handlerTitle = `${this.user.username}: ${countryName}`;
		this.titleHolder.setTitle(`${GENERAL.app.title} | ${this.handlerTitle}`);
	}

}
