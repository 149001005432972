import { Component, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { en as countriesEN } from '@jagomf/countrieslist';
import type { Observable } from 'rxjs';
import { EVENTSWITHFRIENDS, GENERAL } from '../../../app.constants';
import Country from '../../../shared/models/country.model';
import type User from '../../../shared/models/user.model';
import type { IMenuOptions, IMenuFilter } from '../../../shared/services/header.interfaces';
import { FlagsService } from '../../../shared/services/flags.service';
import { FilterableEventsHandlerBaseComponent } from '../../../shared/handlers/filterable-events-handler-base/filterable-events-handler-base.component';
import { SORTBY, SortUsersPipe } from '../../../shared/pipes/sort-users.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserCardListComponent } from '../../../shared/components/user-card-list/user-card-list.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { HandlerActionButtonComponent } from '../../../shared/components/handler-action-button/handler-action-button.component';
import { FlagEventListFilteredComponent } from '../../../shared/components/flag-event-list-filtered/flag-event-list-filtered.component';
import { HeaderHandlerComponent } from '../../../shared/components/header-handler/header-handler.component';

@Component({
	selector: 'dflgr-country-handler',
	standalone: true,
	imports: [RouterOutlet],
	template: `<router-outlet></router-outlet>`,
	styles: [`:host { width: 100%; }`]
})
export class CountryHandlerComponent { }


@Component({
	selector: 'dflgr-country-events-handler',
	standalone: true,
	imports: [HeaderHandlerComponent, FlagEventListFilteredComponent, HandlerActionButtonComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dflgr-header-handler [flag]="country" [title]="handlerTitle" [options]="handlerOptions" [filters]="handlerFilters" [backButton]="true"/>
		<div class="handler-content">
			<dflgr-flag-event-list-filtered [country]="country" (listUpdated)="cdRef.detectChanges()" [showOnlyBy]="showOnlyBy"/>
		</div>
		<dflgr-handler-action-button icon="flag" label="Add flag" [action]="actionButtonAction"/>
	`
})
export class CountryEventsHandlerComponent extends FilterableEventsHandlerBaseComponent {
	@HostBinding('class.handler') readonly handlerClass = true;
	handlerTitle: string = GENERAL.handlers.countries.single.name;
	@Input({ alias: 'countryid', required: true }) set countryId(id: string) { // from route path params
		this.country = new Country(id);
		this.handlerTitle = countriesEN[this.country.id.toUpperCase()];
	}
	country: Country;

	constructor(
		public readonly cdRef: ChangeDetectorRef,
		private readonly flagsProvider: FlagsService,
		private readonly router: Router,
		private readonly route: ActivatedRoute
	) { super(); }

	get actionButtonAction() {
		return this.requestAddFlag.bind(this);
	}

	get handlerOptions(): IMenuOptions[] {
		return [{
			text: 'Users',
			icon: 'people',
			forceShowAction: true,
			callback: () => this.router.navigate([GENERAL.handlers.countries.single.users.path], { relativeTo: this.route })
		}];
	}

	protected toggleFilter(val: EVENTSWITHFRIENDS): void {
		super.toggleFilter(val);
		this.cdRef.detectChanges();
	}

	private requestAddFlag(): void {
		if (this.country) {
			this.flagsProvider.requestAddFlag(this.country);
		}
	}
}


@Component({
	selector: 'dflgr-country-users-handler',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './country-handler.component.html',
	styleUrls: ['./country-handler.component.scss'],
	standalone: true,
	imports: [HeaderHandlerComponent, UserCardListComponent, MatProgressSpinnerModule, AsyncPipe, SortUsersPipe]
})
export class CountryUsersHandlerComponent implements OnInit {
	@HostBinding('class.handler') readonly handlerClass = true;
	handlerTitle: string = GENERAL.handlers.countries.single.name;
	@Input('countryid') set countrySetter(id: string) { // from route path params
		this.country = new Country(id);
		this.handlerTitle = countriesEN[this.country.id.toUpperCase()];
	}
	country: Country;
	usersWithFlag$: Observable<User[]>;
	sortBy = SORTBY.times;

	get handlerSorters(): IMenuFilter[] {
		return [
			{
				text: 'Times',
				singleChoice: true,
				selected: this.sortBy === SORTBY.times,
				callback: this.toggleFilter.bind(this, SORTBY.times)
			},
			{
				text: 'First to get',
				singleChoice: true,
				selected: this.sortBy === SORTBY.first,
				callback: this.toggleFilter.bind(this, SORTBY.first)
			},
			{
				text: 'Last to get',
				singleChoice: true,
				selected: this.sortBy === SORTBY.last,
				callback: this.toggleFilter.bind(this, SORTBY.last)
			}
		] as IMenuFilter[];
	}

	constructor(
		public readonly cdRef: ChangeDetectorRef,
		private readonly flagsProvider: FlagsService,
	) {}

	ngOnInit(): void {
		this.usersWithFlag$ = this.flagsProvider.getUsersByFlag(this.country);
	}

	toggleFilter(val: SORTBY) {
		this.sortBy = val;
		this.cdRef.detectChanges();
	}
}
