import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
	.then(() => {
		if ('serviceWorker' in navigator && environment.production) {
			navigator.serviceWorker.register('ngsw-worker.js');
		}
	})
	.catch(err => console.log(err));
