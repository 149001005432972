<dflgr-header-handler [title]="handlerTitle" [sorters]="handlerSorters" [backButton]="true"/>

<div class="handler-content">
	@if (country) {
		<div class="userlist-container countryflags-list">
			<dflgr-user-card-list [users]="usersWithFlag$ | async | sortUsers:sortBy"/>
		</div>
	} @else {
		<div class="spinner-container"><mat-spinner/></div>
	}
</div>
