<dflgr-header-handler [title]="eventText" [backButton]="true"/>
<div class="handler-content">
	@if (event) {
		<div class="flaglist-container single-event">
			<dflgr-flag-event
				class="item"
				[flagEvent]="event"
				[longText]="true"
				(flagEventDeleted)="moveAway()"/>
		</div>
	} @else {
		<div class="spinner-container"><mat-spinner/></div>
	}
</div>
