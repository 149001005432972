import { inject } from '@angular/core';
import { FlagsService } from '../../shared/services/flags.service';
import type { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import type FlagEvent from '../../shared/models/flag-event.model';
import { of as ObservableOf } from 'rxjs';
import Utils from '../../shared/app.utils';
import { FLAGEVENTS } from '../../app.constants';

export const eventResolver: ResolveFn<FlagEvent> = (
	route: ActivatedRouteSnapshot/* , state: RouterStateSnapshot*/
) => {
	const flagsProvider = inject(FlagsService);

	const flagFullcode = route.paramMap.get('eventid');
	if (flagsProvider.resolvedEvent && flagFullcode === flagsProvider.resolvedEvent.whenLinkStr) {
		return flagsProvider.resolvedEvent;
	}
	const rawEventDate = flagFullcode.substring(0, 14);
	// const eventDate = moment(rawEventDate, FLAGEVENTS.dateTimeRetrieve.fromUrl);
	const eventDate = Utils.getDateFromUTCString(rawEventDate, FLAGEVENTS.dateFromEventUrl);
	if (eventDate) {
		const userid = flagFullcode.replace(/\d{14}/, '');
		return flagsProvider.getSingleEvent(userid, eventDate);
	}
	return ObservableOf(null);
};
