import { Component, HostBinding } from '@angular/core';
import { FlagsService } from '../../../shared/services/flags.service';
import type Country from '../../../shared/models/country.model';
import { GENERAL } from '../../../app.constants';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CountryFlagComponent } from '../../../shared/components/country-flag/country-flag.component';
import { MatCardModule } from '@angular/material/card';
import { NgIf, AsyncPipe } from '@angular/common';
import { HeaderHandlerComponent } from '../../../shared/components/header-handler/header-handler.component';

@Component({
	selector: 'dflgr-countries-list-handler',
	standalone: true,
	imports: [HeaderHandlerComponent, NgIf, MatCardModule, CountryFlagComponent, MatProgressSpinnerModule, AsyncPipe],
	templateUrl: './countries-list-handler.component.html',
	styleUrls: ['./countries-list-handler.component.scss']
})
export class CountriesListHandlerComponent {
	@HostBinding('class.handler') readonly handlerClass = true;
	readonly handlerTitle: string = GENERAL.handlers.countries.name;

	readonly countryList$ = this.flagsProvider.getCountriesList(true);
	currentLetter = '';

	constructor(private readonly flagsProvider: FlagsService) { }

	isNewLetter(countryName: string): string | boolean {
		const initial = countryName.substring(0, 1);
		return this.currentLetter !== initial ? this.currentLetter = initial : false;
	}

	trackByCountryName(index, country: Country) {
		return country.name;
	}

}
