import { inject } from '@angular/core';
import type { ResolveFn, ActivatedRouteSnapshot } from '@angular/router';
import { of as ObservableOf } from 'rxjs';
import type User from '../../shared/models/user.model';
import { UsersService } from '../../shared/services/users.service';
import { StorageService } from '../../shared/services/storage.service';

export const userResolver: ResolveFn<User> = (
	route: ActivatedRouteSnapshot/*, state: RouterStateSnapshot */
) => {
	const storageService = inject(StorageService);

	const username = route.paramMap.get('user');
	// const username = route.paramMap.get('user');

	// If user ID specified, return that user
	if (username) {
		const usersProvider = inject(UsersService);

		// If self, return
		if (username === storageService.localUser.username) {
			return ObservableOf(storageService.localUser);
		}

		// Else, get from provider or retrieve from API
		const resolvedUser = usersProvider.resolvedUser;
		// Clear cache after resolved User is retrieved
		usersProvider.resolvedUser = null;

		return resolvedUser?.username.toString() === username.toString()
			? ObservableOf(resolvedUser)
			: usersProvider.getUserInfo(username);
	}

	// else return local user
	return ObservableOf(storageService.localUser);
};
