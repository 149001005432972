import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { UserHandlerComponent, UserEventsHandlerComponent } from './handlers/user-handler/user-handler.component';
import { UserCountryHandlerComponent } from './handlers/user-country-handler/user-country-handler.component';
import { UsersRoutingModule } from './users-routing.module';
import { ProfileEditHandlerComponent } from './handlers/profile-edit-handler/profile-edit-handler.component';

@NgModule({
	imports: [
		SharedModule,
		UsersRoutingModule,
		UserHandlerComponent,
		ProfileEditHandlerComponent,
		UserEventsHandlerComponent,
		UserCountryHandlerComponent
	],
})
export class UsersModule {}
