<header>
	<dflgr-header-app class="app-toolbar-container" [title]="title"/>
</header>
<main #main>
	<router-outlet/>
</main>
<nav *ngIf="showLogged">
	<dflgr-nav-icon *ngFor="let link of list"
		[badge]="link.badge"
		[path]="link.path"
		[icon]="link.icon"
		[text]="link.text"/>
</nav>
